<template>
    <v-card
        class="offwhite-card team-card"
        elevation="0"
    >
        <div class="image">
            <v-img
                :src="user.image.url"
                :aspect-ratio="1"
                class="grey lighten-2"
                v-if="user.image"
            >
            </v-img>
            <div class="icon" v-else>
                <v-icon
                >
                    fa fa-user
                </v-icon>
            </div>
        </div>
        <div class="content">
            <v-card-title class="pt-0">
                <h2 class="text-h6">{{ user.name }}</h2>
            </v-card-title>
            <v-card-subtitle><em>{{ user.subtitle }}</em></v-card-subtitle>
            <v-card-text>
                <a :href="'mailto:' + user.email" v-if="user.email">
                    {{ user.email }}
                </a>
            </v-card-text>
        </div>
    </v-card>
</template>

<script>
export default {
    name: "TeamMemberCard",
    props: ['user'],
}
</script>

<style scoped>

</style>
