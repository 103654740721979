<template>
    <v-container>
        <v-row class="align-items-stretch mb-12 justify-center pt-3">
            <v-col cols="12" sm="6" lg="4">
                <v-card
                    class="offwhite-card d-flex flex-column"
                    elevation="0"
                    height="100%"
                >
                    <v-card-title class="line justify-center">Helpdesk</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p class="helpdesk-links d-flex align-end justify-center gap-1 flex-wrap">
                            <v-btn
                                tile
                                color="accent"
                                href="tel:0031773069600"
                                elevation="0"
                                large
                                block
                            >
                                <v-icon left small>
                                    fa fa-phone-alt
                                </v-icon>
                                +31 77 306 9600
                            </v-btn>
                            <v-btn
                                tile
                                color="accent"
                                href="https://wa.me/31777820190"
                                target="_blank"
                                elevation="0"
                                large
                                block
                            >
                                <v-icon left small>
                                    fab fa-whatsapp
                                </v-icon>
                                +31 77 782 0190
                            </v-btn>

                            <!--                            <a href="mailto:technicalservice@fancom.com" class="ml-auto">-->
                            <!--                                <v-icon-->
                            <!--                                    class="mr-1"-->
                            <!--                                    x-small-->
                            <!--                                >-->
                            <!--                                    fal fa-envelope-->
                            <!--                                </v-icon>-->
                            <!--                                technicalservice@fancom.com-->
                            <!--                            </a>-->
                        </p>
                        <p class="mb-0 d-flex mt-auto" v-if="settings">
                            <span v-if="closed">Closed</span>
                            <span v-else>
                                from {{ openTime|luxon({ input: { zone: this.supportTimezone }, output: { format: 't', zone: this.supportTimezone } }) }}
                                - {{ closeTime|luxon({ input: { zone: this.supportTimezone }, output: { format: 't', zone: this.supportTimezone } }) }} (CET)</span>

                            <span v-if="isHelpdeskOnline" class="ml-auto green--text">
                                <v-icon x-small color="green">fas fa-circle</v-icon>
                                Online
                            </span>
                            <span v-else class="ml-auto red--text">
                                <v-icon x-small color="red">fas fa-circle</v-icon>
                                Offline
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-card
                    class="offwhite-card d-flex flex-column"
                    elevation="0"
                    height="100%"
                >
                    <v-card-title class="line justify-center">Service Portal</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p class="helpdesk-links d-flex align-end justify-center">
                            <a class="mx-2" href="https://support.fancom.com" target="_blank">
                                <img src="/img/logo_salesIQ.png" alt="Sales IQ" class="d-block" />
                            </a>
                        </p>
                        <p class="mb-0 d-flex" v-if="settings">
                            <span v-if="closed">Closed</span>
                            <span v-else><small>We're here to help | Tickets, Knowledgebase</small></span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <v-card
                    class="offwhite-card d-flex flex-column"
                    elevation="0"
                    height="100%"
                >
                    <v-card-title class="line justify-center">Web Support</v-card-title>
                    <v-card-text class="flex-fill d-flex align-center justify-center">
                        <p class="mb-0">
                            <v-btn x-large elevation="0" :disabled="(!settings || !settings.teamviewerUrl)" :loading="(!settings || !settings.teamviewerUrl)" :href="settings && settings.teamviewerUrl ? settings.teamviewerUrl : null" target="_blank" color="primary">
                                <v-icon
                                    left
                                >
                                    fal fa-user-headset
                                </v-icon>
                                Download Fancom Teamviewer
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <div class="mb-8 line-bottom pb-4">
            <h2 class="text-h4 mb-3 mb-md-0 text-center text--accent">Contact by Email</h2>
        </div>
        <p class="mb-9 text-center">The Fancom team consists of almost 100 dedicated employees. Who can you contact for which work area?<br />
            Choose the right email address for all your questions for quick support:</p>
        <v-row class="align-items-stretch mb-14">
            <v-col cols="12" sm="6" lg="3">
                <v-card
                    class="d-flex flex-column offwhite-card "
                    elevation="0"
                    height="100%"
                >
                    <v-card-title class="line text-h5 pa-3 justify-center">Technical Service</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p>For all your questions about:</p>
                        <ul class="mb-4">
                            <li>Technical product support</li>
                            <li>Spare parts</li>
                            <li>Commissioning</li>
                            <li>Service visit request</li>
                        </ul>
                        <p>
                            <v-btn
                                large
                                block
                                href="mailto:technicalservice@fancom.com"
                                color="accent"
                                class="email"
                                elevation="0"
                            >
                                technicalservice@fancom.com
                            </v-btn>
                        </p>
                        <p class="mb-0"><small>If our helpdesk cannot answer your question right away, your question will be sent to our Call handling system, after which one of our support engineers will contact you.</small></p>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" lg="3">
                <v-card
                    class="offwhite-card d-flex flex-column"
                    elevation="0"
                    height="100%"
                >
                    <v-card-title class="line text-h5 pa-3 justify-center">Field Application Specialist</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p>For all your questions about:</p>
                        <ul class="mb-4">
                            <li>Application possibilities of our products and systems</li>
                            <li>Technical project support</li>
                        </ul>

                        <p>please contact the area sales manager for <a href="https://www.fancom.com/distributors" title="Distributors" target="_blank">your area</a>.</p>

                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" lg="3">
                <v-card
                    class="offwhite-card d-flex flex-column"
                    elevation="0"
                    height="100%"
                >
                    <v-card-title class="line text-h5 pa-3 justify-center">Logistics</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p>For all your questions about:</p>
                        <ul class="mb-4">
                            <li>Your orders</li>
                            <li>Deliveries</li>
                            <li>Article numbers</li>
                            <li>Shipment</li>
                        </ul>
                        <p>
                            <v-btn
                                large
                                block
                                href="mailto:logistics@fancom.com"
                                color="accent"
                                class="email"
                                elevation="0"
                            >
                                logistics@fancom.com
                            </v-btn>
                        </p>
                        <p class="mb-0">
                            <v-btn
                                large
                                block
                                href="tel:+31773069649"
                                color="accent"
                                class=""
                                elevation="0"
                            >
                                +31 77 306 96 49
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" lg="3" class="d-flex flex-column">
                <v-card
                    class="offwhite-card d-flex flex-column mb-7"
                    elevation="0"
                >
                    <v-card-title class="line text-h5 pa-3 justify-center">Finance</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p>For all your questions about:</p>
                        <ul class="mb-4">
                            <li>Invoices</li>
                        </ul>
                        <p class="mb-0">
                            <v-btn
                                large
                                block
                                href="mailto:ar@fancom.com"
                                color="accent"
                                class="email"
                                elevation="0"
                            >
                                ar@fancom.com
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
                <v-card
                    class="offwhite-card d-flex flex-column flex-fill"
                    elevation="0"
                >
                    <v-card-title class="line text-h5 pa-3 justify-center">Sales</v-card-title>
                    <v-card-text class="flex-fill d-flex flex-column">
                        <p>For commercial matters, please contact the area sales manager for <a href="https://www.fancom.com/distributors" title="Distributors" target="_blank">your area</a>.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="team-overview mb-4">
            <v-col v-for="user in paginatedData" :key="user.id" cols="12" sm="6" md="4" lg="3" class="mb-5">
                <TeamMemberCard :user="user"></TeamMemberCard>
            </v-col>
        </v-row>
        <v-pagination v-model="pagination.pageNumber" :length="pages" :total-visible="10" v-if="filteredUsers.length > pagination.itemsPerPage"
                      @input="nextPage" class="mt-5 mb-5"></v-pagination>
    </v-container>
</template>

<script>
import TeamMemberCard from '../components/team/TeamMemberCard.vue';
import { mapState } from 'vuex';
import { DateTime, Interval } from 'luxon';

export default {
    name: "Contact",
    components: {TeamMemberCard},
    data: () => ({
        isHelpdeskOnline: false,
        onlineInterval: null,
        pagination: {
            pageNumber: 1,
            itemsPerPage: 50
        },
        breadcrumbs: [
            {
                text: 'Home',
                disabled: false,
                to: { name: 'Home' },
                exact: true
            },
            {
                text: 'Contact',
                disabled: false,
                to: { name: 'Contact' },
                exact: true
            }
        ],
        supportTimezone: 'Europe/Amsterdam'
    }),
    mounted() {
        this.$store.dispatch('getTeamMembers', {forceReload: true})
        this.$store.dispatch('setPageTitle', 'Contact')
        this.$store.dispatch('getSettings').then(() => {
            this.checkIsHelpdeskOnline();
        });
        this.onlineInterval = setInterval(() => this.checkIsHelpdeskOnline, 60000);
    },
    beforeDestroy() {
        clearInterval(this.onlineInterval);
    },
    computed: {
        pages() {
            return Math.ceil(this.teamMembers.length / this.pagination.itemsPerPage)
        },
        paginatedData() {
            const start = this.pagination.pageNumber * this.pagination.itemsPerPage - this.pagination.itemsPerPage, //sets the correct start on data
                end = start + this.pagination.itemsPerPage;
            return this.filteredUsers.slice(start, end);
        },
        filteredUsers () {
            return this.teamMembers.filter(user => user.email !== 'support@forwart.nl');
        },
        openTime() {
            return this.settings['openTime'+(new Date()).getDay()];
        },
        closeTime() {
            return this.settings['closeTime'+(new Date()).getDay()];
        },
        closed() {
            return !this.settings['openDay'+(new Date()).getDay()];
        },
        ...mapState({
            teamMembers: state => state.team.teamMembers,
            settings: state => state.general.settings,
        })
    },
    methods: {
        nextPage(page) {
            this.pagination.pageNumber = page;
        },
        checkIsHelpdeskOnline () {
            if (!this.settings || this.closed) {
                this.isHelpdeskOnline = false;
                return;
            }

            // Create opening hours in the support timezone
            const supportStartTime = DateTime.fromISO(this.openTime.substring(11, 19), { zone: this.supportTimezone});
            const supportEndTime = DateTime.fromISO(this.closeTime.substring(11, 19), { zone: this.supportTimezone});

            // Never on Saturday and Sunday
            if ([6, 7].includes(supportStartTime.weekday)) {
                return false
            }

            // Create interval and check if time is in the tinterval
            const supportInterval = Interval.fromDateTimes(supportStartTime, supportEndTime);
            const now = DateTime.local();

            this.isHelpdeskOnline = supportInterval.contains(now);
        },
        openZohoChat() {
            window.$zoho.salesiq.chat.start();
        },
    },
}
</script>

<style scoped>

</style>
